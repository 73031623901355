/*!
 * Atruvia LinkedIn Briefing
 *
 * @copyright 2021 Dennis Morhardt, ressourcenmangel an der Panke GmbH
 */
@charset 'UTF-8';

/**
 * Abstracts
 */
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

/**
 * Vendors
 */
@import 'sass-mq/mq';

/**
 * Base
 */
@import 'base/reset';
@import 'base/fonts';
@import 'base/root';
@import 'base/body';
@import 'base/utils';
@import 'base/focus';
@import 'base/reduced-motion';
@import 'base/grid-overlay';
@import 'base/toast';

/**
 * Layouts
 */
@import 'layouts/page';

/**
 * Particles
 */
@import '../components/0-particles/icon/icon';
@import '../components/0-particles/preview/preview';

/**
 * Atoms
 */
@import '../components/1-atoms/button/button';
@import '../components/1-atoms/copyright/copyright';
@import '../components/1-atoms/field/field';
@import '../components/1-atoms/headline/headline';
@import '../components/1-atoms/image/image';
@import '../components/1-atoms/link/link';
@import '../components/1-atoms/text-button/text-button';

/**
 * Molecules
 */
@import '../components/2-molecules/teaser/teaser';
@import '../components/2-molecules/field-group/field-group';

/**
 * Organisms
 */
@import '../components/3-organisms/form/form';
@import '../components/3-organisms/header/header';
@import '../components/3-organisms/login-splash/login-splash';
@import '../components/3-organisms/modal/modal';
@import '../components/3-organisms/page-header/page-header';
@import '../components/3-organisms/teaser-list/teaser-list';
