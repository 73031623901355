.teaser {
  background-color: $color-white;
  padding: 2.4rem;

  > * + * {
    margin-top: 2.4rem;
  }
}

.teaser__meta {
  color: $color-ui-gray;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
}

.teaser__meta-item:first-child {
  color: $color-blue-1;
  font-weight: bold;
}

.teaser__meta-item {
  & + & {
    margin-left: 1.6rem;
  }
}

.teaser__comment {
  border-bottom: 1px solid $color-gray;
  color: $color-ui-gray;
  font-size: 1.6rem;
  line-height: $line-height-body;
  padding-bottom: 2.4rem;

  > * + * {
    margin-top: 1.2rem;
  }
}

.teaser__text {
  font-size: 1.8rem;
  line-height: $line-height-body;

  > * + * {
    margin-top: 1.2rem;
  }
}

.teaser__link-preview {
  display: flex;
  position: relative;
}

.teaser__link-preview-image {
  flex-shrink: 0;
  margin-right: 2.4rem;
  width: 10rem;
}

.teaser__link-preview-description {
  font-size: 1.4rem;
  line-height: $line-height-body;
  margin-top: 1.2rem;
}

.teaser__link-preview-domain {
  color: $color-ui-gray;
  font-size: 1.2rem;
  margin-top: 1.2rem;
}

.teaser__linkedin-post {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: 40rem;
  justify-content: stretch;

  * {
    flex-grow: 1;
  }
}

.teaser__actions {
  border-top: 1px solid $color-gray;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 3.2rem;
}

.teaser__action {
  padding-top: 2.4rem;

  & + & {
    margin-left: 2.4rem;
  }
}
