.preview {
  min-height: 100vh;
  padding: 2rem;
}

.preview--paddingless {
  padding: 0;
}

.preview--blue-3 {
  background-color: $color-blue-3;
}
