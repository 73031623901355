:root {
  --button-background-color: #{$color-white};
  --button-border-color: var(--button-background-color);
  --button-color: #{$color-blue-2};
  --button-focus-background-color: #{$color-blue-3};
  --button-focus-border-color: var(--button-focus-background-color);
  --button-focus-color: #{$color-blue-2};
}

.button {
  background-color: transparent;
  //background-color: var(--button-background-color);
  border: 2px solid var(--button-border-color);
  //border-radius: 2.7rem;
  //color: var(--button-color);
  color: var(--button-focus-color);
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 700;
  height: 5.4rem;
  line-height: 1.8rem;
  padding: 1.5rem 3.2rem;
  text-align: center;
  transition-property: background-color, border-color, color, transform;
  user-select: none;

  &:not([disabled]):hover,
  &:focus,
  .has-button-focus:hover &:not([disabled]),
  .has-button-focus:focus-within & {
    background-color: var(--button-background-color);
    color: var(--button-color);
    //background-color: var(--button-focus-background-color);
    //color: var(--button-focus-color);
    border-color: var(--button-focus-border-color);
    span {
      color: var(--button-color)
    }
  }

  &:active {
    transform: scale(0.95);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.button--primary {
  --button-background-color: #{$color-blue-2};
  --button-border-color: #{$color-blue-2};
  --button-color: #{$color-white};
  --button-focus-background-color: #{$color-white};
  --button-focus-border-color: #{$color-blue-2};
  --button-focus-color: #{$color-blue-2};
}

.button--secondary {
  --button-background-color: #{$color-white};
  --button-border-color: #{$color-blue-2};
  --button-color: #{$color-blue-2};
  --button-focus-background-color: #{$color-blue-2};
  --button-focus-border-color: #{$color-blue-2};
  --button-focus-color: #{$color-white};
}

.button--tertiary {
  --button-background-color: #{$color-blue-3};
  --button-border-color: #{$color-blue-3};
  --button-color: #{$color-blue-1};
  --button-focus-background-color: #{$color-blue-1};
  --button-focus-border-color: #{$color-blue-1};
  --button-focus-color: #{$color-white};
}
