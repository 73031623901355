@use 'sass:math';

// Colors
$color-black: #000;
$color-white: #fff;
$color-blue-1: rgb(27, 33, 25);
$color-blue-2: rgb(255, 75, 50);
$color-blue-3: rgb(20, 144, 224);//rgb(25, 186, 174);
$color-orange: rgb(255, 75, 50);
$color-red: $color-orange;
$color-gray: $color-blue-3;
$color-ui-gray: $color-blue-3;

// Root
$root-color: $color-black;
$root-font-size-px: 10px;
$root-min-width: 320px;

// Body
$body-background-color: $color-white;
$body-font-size: 1.6rem;

// Link
$link-color: $color-blue-2;
$link-hover-color: $color-blue-1;

// Skip link
$skip-link-background-color: $color-black;
$skip-link-color: $color-white;

// Font families
$font-stack-body: 'FAGO', Helvetica, Arial, sans-serif;
$font-stack-root: $font-stack-body;

// Line height
$line-height-headline: 1.2;
$line-height-body: 1.5;

// Durations
$duration-default: 0.2s;
$duration-long: 0.4s;

// Z-Index
$z-index-list: 'modal', 'skip-link', 'page-header';

// Focus
$focus-outline-color: $color-black;

// Columns
$columns: 12;

// Gap
$gaps: (
  xs: 2.4rem,
  s: 2.4rem,
  m: 2.4rem,
  l: 2.4rem,
  xl: 2.4rem,
);

// stylelint-disable length-zero-no-unit
$mq-breakpoints: (
  xs: 0px,
  s: 600px,
  m: 768px,
  l: 1024px,
  xl: 1440px,
);
// stylelint-enable

// Inner container
$inner-container-padding: (
  xs: 5vw,
  s: 5vw,
  m: 5vw,
  l: 5vw,
  xl: 5vw,
);

$inner-container-max-width: 144rem;
$inner-container-max-padding: math.div(map-get($inner-container-padding, xl), 100vw) * $inner-container-max-width;

// Exports
:export {
  l: map-get($mq-breakpoints, l);
  m: map-get($mq-breakpoints, m);
  s: map-get($mq-breakpoints, s);
  xl: map-get($mq-breakpoints, xl);
  xs: map-get($mq-breakpoints, xs);
}
