@font-face {
  font-family: 'FAGO';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/FagoNo.otf') format('opentype');
}

@font-face {
  font-family: 'FAGO';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/FagoNo-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'FAGO';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/FagoNoTf-Bold.otf') format('opentype');
}



//@font-face {
//  font-family: 'ATRUVIA';
//  font-style: normal;
//  font-weight: normal;
//  src: url('../../fonts/atruvia-regular.woff2') format('woff2'), url('../../fonts/atruvia-regular.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'ATRUVIA';
//  font-style: normal;
//  font-weight: 600;
//  src: url('../../fonts/atruvia-medium.woff2') format('woff2'), url('../../fonts/atruvia-medium.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'ATRUVIA';
//  font-style: normal;
//  font-weight: 700;
//  src: url('../../fonts/atruvia-bold.woff2') format('woff2'), url('../../fonts/atruvia-bold.woff') format('woff');
//}
//
