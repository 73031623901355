.teaser-list {
  background-color: $color-blue-3;
}

.teaser-list__inner {
  @include use-container();
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 4rem,
    s: 4.8rem,
    m: 5.6rem,
    l: 6.4rem,
    xl: 7.2rem,
  ));
}

.teaser-list__teasers {
  @include use-responsive-sizing(width, (
    xs: 100%,
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  margin-left: auto;
  margin-right: auto;
}

.teaser-list__teaser {
  & + & {
    margin-top: 3.2rem;
  }
}

.teaser-list__pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}
