.field-group__label {
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 2.4rem;
  width: 100%;
}

.field-group__field {
  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  &[data-conditionally-hidden='true'] {
    display: none;
  }
}

.field-group__error,
.field-group__description {
  display: flex;
  font-size: 1.6rem;
  margin-top: 2rem;
}

.field-group__error {
  color: $color-red;
  font-weight: bold;
}

.field-group__description-icon,
.field-group__error-icon {
  flex-shrink: 0;
  margin-right: 0.8rem;
}
