.page-header {
  background-color: $color-white;
}

.page-header__inner {
  @include use-container();
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 2.4rem,
    s: 3.2rem,
    m: 4rem,
    l: 4.8rem,
  ));

  position: relative;
  z-index: z-index('page-header');

  &::after {
    border-left: 2rem solid transparent;
    border-right: 2rem solid transparent;
    border-top: 2rem solid $color-white;
    bottom: -2rem;
    content: '';
    height: 0;
    position: absolute;
    width: 0;
    z-index: 2;
  }
}
