:root {
  --text-button-color: #{$color-blue-1};
  --text-button-icon-color: #{$color-blue-2};
  --text-button-hover-color: #{$color-blue-1};
  --text-button-icon-hover-color: #{$color-blue-1};
}

.text-button {
  --underline-color: var(--text-button-color);
  --underline-focus-color: var(--text-button-hover-color);

  align-items: center;
  color: var(--text-button-color);
  display: inline-flex;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2.4rem;
  transition-property: color;
  user-select: none;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]):hover,
  &:focus {
    color: var(--text-button-hover-color);
  }
}

.text-button__icon {
  color: var(--text-button-icon-color);
  font-size: 2rem;
  line-height: 0;
  transition-property: color;

  .text-button:not([disabled]):hover &,
  .text-button:focus & {
    color: var(--text-button-icon-hover-color);
  }
}

.text-button__icon--left {
  margin-right: 0.8rem;
}

.text-button__icon--right {
  margin-left: 0.8rem;
  order: 1;
}
