.header {
  background-color: $color-blue-1;
  color: $color-white;
}

.header__inner {
  @include use-container();

  padding-bottom: 2.4rem;
  padding-top: 2.4rem;

  @include mq($from: m) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.header__logo {
  align-items: center;
  display: flex;
}

.header__logo-img {
  margin-right: 2rem;
  max-width: 4rem;
}

.header__logo-text {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 4rem;
}

.header__user {
  align-items: center;
  display: flex;
  margin-top: 2.4rem;

  @include mq($from: m) {
    margin-top: 0;
  }
}

.header__user-image {
  border-radius: 50%;
  flex-shrink: 0;
  height: 4rem;
  width: 4rem;
}

.header__user-name {
  font-size: 1.8rem;
  font-weight: 700;
  margin-left: 1.2rem;
}

.header__user-logout {
  --text-button-color: #{$color-white};
  --text-button-hover-color: #{$color-white};
  --text-button-icon-hover-color: #{$color-white};

  border-left: 1px solid rgba($color-white, 0.2);
  margin-left: 2.4rem;
  padding-left: 2.4rem;
}
