html {
  color: $root-color;
  font-family: $font-stack-root;
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  height: 100%;
  line-height: 1;
  min-height: 100%;
  min-width: $root-min-width;
  scroll-behavior: smooth;
}
