.login-splash {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include mq($from: m) {
    flex-direction: row;
  }
}

.login-splash__image {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  justify-content: center;
  padding: 10vw 5vw;

  .image {
    flex-grow: unset;
  }

  @include mq($from: m) {
    flex-grow: 1;
    height: auto;
    width: 50%;
  }
}

.login-splash__content {
  background-color: $color-blue-1;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  justify-content: center;
  padding: 10vw 5vw;

  @include mq($from: m) {
    flex-grow: 1;
    width: 50%;
  }
}

.login-splash__logo-img {
  max-width: 15rem;
}

.login-splash__logo-text {
  color: $color-white;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin-top: 1.2rem;
}

.login-splash__form {
  margin-top: 3.2rem;
}
