.form {
  font-size: 1.6rem;
  line-height: $line-height-body;
}

.form__headline,
.form__alert {
  margin-bottom: 3.2rem;
}

.form__progress:last-child {
  margin-bottom: 0;
  margin-top: 3.2rem;
}

.form__inner {
  display: flex;
}

.form__tabs {
  flex-shrink: 0;
  margin-right: 2.4rem;
  min-width: 20rem;
  padding-left: 2rem;
  width: 20%;
}

.form__tab {
  opacity: 0.5;

  & + & {
    margin-top: 0.8rem;
  }
}

.form__tab--active {
  font-weight: bold;
  opacity: 1;
}

.form__pages {
  flex-grow: 1;
}

.form__submit {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
}
